import React from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { Link, useLocation } from "react-router-dom";
import { useAnchorWallet } from "@solana/wallet-adapter-react";

import CurrencyToggle from "./CurrencyToggle";
import styled from "styled-components";
import { RouteName } from "../constant/routeNames";

interface TopNavProps {
  showCurrencyToggle?: boolean;
}

const ROUTES = [
  { url: RouteName.home, name: "Marketplace" },
  { url: RouteName.sell, name: "Sell" },
  { url: RouteName.auctionsView, name: "Auctions" },
  { url: RouteName.drop, name: "Edition Drops" }
];

const TopNav: React.FC<TopNavProps> = ({ showCurrencyToggle = false }) => {
  const wallet = useAnchorWallet();

  const { pathname } = useLocation();
  return (
    <HeaderBar>
      <Logo>
        <Link to={RouteName.home}>
        <img alt="bascMarketplace" src="/logotransparent.gif" />
        </Link>
      </Logo>
      <Menu>
        {ROUTES.map((item) => (
          <li key={item.url} className={pathname === item.url ? "active" : ""}>
            <Link to={item.url}>{item.name}</Link>
          </li>
        ))}
        <li><a href="https://dazing.bascdao.net/">Staking</a></li>
      </Menu>
      {showCurrencyToggle && <CurrencyToggle />}
      <Wallet>
        {wallet ? (
          <ConnectButton />
        ) : (
          <ConnectButton>Connect Wallet</ConnectButton>
        )}
      </Wallet>
    </HeaderBar>
  );
};

const HeaderBar = styled.div`
  margin-top: 20px;
  margin-left: 40px;
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const Wallet = styled.ul`
  flex: 0 0 auto;
  margin: 0;
  padding: 0;
`;

const ConnectButton = styled(WalletMultiButton)`
  padding: 6px 16px;
  border-radius: 12px !important;
  background-color: #4e44ce;
  margin: 0 auto;
`;

const Logo = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;

  img {
    height: 60px;
  }
`;

const Menu = styled.ul`
  list-style: none;
  display: inline-flex;
  flex: 1 0 auto;
  margin-bottom: 0;

  > .active {
    border-bottom: 4px solid var(--title-text-color);
  }

  > .active-submenu {
    background-color: rgba(255, 255, 255, 0.08);
  }

  > li {
    margin: 0 12px;
    padding: 5px;

    a {
      color: var(--main-text-color);
      list-style-image: none;
      list-style-position: outside;
      list-style-type: none;
      outline: none;
      text-decoration: none;
      text-size-adjust: 100%;
      touch-action: manipulation;
      transition: color 0.3s;

      img {
        max-height: 26px;
      }
    }

    &:hover,
    &:active {
      border-bottom: 4px solid var(--title-text-color);
    }

    &:hover > a {
      color: rgb(131, 146, 161);
    }
  }
`;

export default TopNav;
